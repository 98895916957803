import { openedModals } from '../stores';
import { get } from 'svelte/store';
import uuidv1 from 'uuid/v1';
import invalidateCommands from '../commands/invalidateCommands';
export function showModal(component, props = {}) {
    const modalId = uuidv1();
    openedModals.update(x => [...x, { component, modalId, props }]);
    invalidateCommands();
}
export function closeModal(modalId) {
    openedModals.update(x => x.filter(y => y.modalId != modalId));
    invalidateCommands();
}
export function closeCurrentModal() {
    openedModals.update(modals => modals.slice(0, modals.length - 1));
    invalidateCommands();
}
export function getActiveModalId() {
    var _a;
    const modals = get(openedModals);
    return (_a = modals[modals.length - 1]) === null || _a === void 0 ? void 0 : _a.modalId;
}
