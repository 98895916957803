export function matchDatabaseObjectAppObject(obj1, obj2) {
    return ((obj1 === null || obj1 === void 0 ? void 0 : obj1.objectTypeField) == (obj2 === null || obj2 === void 0 ? void 0 : obj2.objectTypeField) &&
        (obj1 === null || obj1 === void 0 ? void 0 : obj1.conid) == (obj2 === null || obj2 === void 0 ? void 0 : obj2.conid) &&
        (obj1 === null || obj1 === void 0 ? void 0 : obj1.database) == (obj2 === null || obj2 === void 0 ? void 0 : obj2.database) &&
        (obj1 === null || obj1 === void 0 ? void 0 : obj1.pureName) == (obj2 === null || obj2 === void 0 ? void 0 : obj2.pureName) &&
        (obj1 === null || obj1 === void 0 ? void 0 : obj1.schemaName) == (obj2 === null || obj2 === void 0 ? void 0 : obj2.schemaName));
}
function getTableLikeActions(dataTab) {
    return [
        {
            label: 'Open data',
            tab: dataTab,
            defaultActionId: 'openTable',
        },
        // {
        //   label: 'Open form',
        //   tab: dataTab,
        //   initialData: {
        //     grid: {
        //       isFormView: true,
        //     },
        //   },
        //   defaultActionId: 'openForm',
        // },
        {
            label: 'Open structure',
            tab: 'TableStructureTab',
            icon: 'img table-structure',
            defaultActionId: 'openStructure',
        },
        {
            label: 'Show SQL',
            tab: 'SqlObjectTab',
            defaultActionId: 'showSql',
            icon: 'img sql-file',
        },
    ];
}
export const defaultDatabaseObjectAppObjectActions = {
    tables: getTableLikeActions('TableDataTab'),
    views: getTableLikeActions('ViewDataTab'),
    matviews: getTableLikeActions('ViewDataTab'),
    procedures: [
        {
            label: 'Show SQL',
            tab: 'SqlObjectTab',
            defaultActionId: 'showSql',
            icon: 'img sql-file',
        },
    ],
    functions: [
        {
            label: 'Show SQL',
            tab: 'SqlObjectTab',
            defaultActionId: 'showSql',
            icon: 'img sql-file',
        },
    ],
    collections: [
        {
            label: 'Open data',
            tab: 'CollectionDataTab',
            defaultActionId: 'openTable',
        },
        {
            label: 'Open JSON',
            tab: 'CollectionDataTab',
            defaultActionId: 'openJson',
            initialData: {
                grid: {
                    isJsonView: true,
                },
            },
        },
    ],
};
