import { __awaiter } from "tslib";
import _ from 'lodash';
import { currentDatabase, focusedConnectionOrDatabase, getActiveTab, getCurrentDatabase, getLockedDatabaseMode, openedTabs, selectedDatabaseObjectAppObject, } from '../stores';
import { shouldShowTab } from '../tabpanel/TabsPanel.svelte';
import { getAppLoaded } from './appLoadManager';
import { getConnectionInfo } from './metadataLoaders';
import { switchCurrentDatabase } from './common';
// let lastCurrentTab = null;
// openedTabs.subscribe(value => {
//   const newCurrentTab = (value || []).find(x => x.selected);
//   if (newCurrentTab == lastCurrentTab) return;
//   if (getLockedDatabaseMode() && getCurrentDatabase()) return;
//   const lastTab = lastCurrentTab;
//   lastCurrentTab = newCurrentTab;
//   // if (lastTab?.tabComponent == 'ConnectionTab') return;
//   if (newCurrentTab) {
//     const { conid, database } = newCurrentTab.props || {};
//     if (conid && database && (conid != lastTab?.props?.conid || database != lastTab?.props?.database)) {
//       const doWork = async () => {
//         const connection = await getConnectionInfo({ conid });
//         switchCurrentDatabase({
//           connection,
//           name: database,
//         });
//       };
//       callWhenAppLoaded(doWork);
//     }
//   }
// });
export function handleAfterTabClick() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const currentTab = getActiveTab();
        const { conid, database, objectTypeField, pureName, schemaName, defaultActionId } = (currentTab === null || currentTab === void 0 ? void 0 : currentTab.props) || {};
        const db = getCurrentDatabase();
        if (conid && database && (conid != ((_a = db === null || db === void 0 ? void 0 : db.connection) === null || _a === void 0 ? void 0 : _a._id) || database != (db === null || db === void 0 ? void 0 : db.name))) {
            const connection = yield getConnectionInfo({ conid });
            switchCurrentDatabase({
                connection,
                name: database,
            });
            // const doWork = async () => {
            //   const connection = await getConnectionInfo({ conid });
            //   switchCurrentDatabase({
            //     connection,
            //     name: database,
            //   });
            // };
            // callWhenAppLoaded(doWork);
        }
        if (conid && database && objectTypeField && pureName && defaultActionId) {
            selectedDatabaseObjectAppObject.set({
                conid,
                database,
                objectTypeField,
                pureName,
                schemaName,
            });
        }
        // focus current tab
        openedTabs.update(tabs => {
            return tabs.map(tab => (Object.assign(Object.assign({}, tab), { focused: !!tab.selected && !tab.closedTime })));
        });
    });
}
currentDatabase.subscribe(currentDb => {
    var _a;
    if (currentDb) {
        focusedConnectionOrDatabase.set({
            conid: (_a = currentDb.connection) === null || _a === void 0 ? void 0 : _a._id,
            database: currentDb.name,
            connection: currentDb.connection,
        });
    }
    else {
        focusedConnectionOrDatabase.set(null);
    }
    if (!getLockedDatabaseMode())
        return;
    if (!currentDb && !getAppLoaded())
        return;
    openedTabs.update(tabs => {
        const newTabs = tabs.map(tab => (Object.assign(Object.assign({}, tab), { selected: tab.selected && shouldShowTab(tab, true, currentDb) })));
        if (newTabs.find(x => x.selected))
            return newTabs;
        const selectedIndex = _.findLastIndex(newTabs, x => shouldShowTab(x));
        return newTabs.map((x, index) => (Object.assign(Object.assign({}, x), { selected: index == selectedIndex })));
    });
});
