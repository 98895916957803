import { __awaiter } from "tslib";
import { findDefaultSchema, findEngineDriver, isCompositeDbName } from 'dbgate-tools';
import { currentDatabase, getExtensions, getOpenedTabs, loadingSchemaLists, openedTabs } from '../stores';
import _ from 'lodash';
import { getSchemaList } from './metadataLoaders';
import { showSnackbarError } from './snackbar';
export class LoadingToken {
    constructor() {
        this.isCanceled = false;
    }
    cancel() {
        this.isCanceled = true;
    }
}
export function sleep(milliseconds) {
    return new Promise(resolve => window.setTimeout(() => resolve(null), milliseconds));
}
export function changeTab(tabid, changeFunc) {
    openedTabs.update(files => files.map(tab => (tab.tabid == tabid ? changeFunc(tab) : tab)));
}
export function markTabUnsaved(tabid) {
    const tab = getOpenedTabs().find(x => x.tabid == tabid);
    if (tab.unsaved)
        return;
    openedTabs.update(files => files.map(tab => (tab.tabid == tabid ? Object.assign(Object.assign({}, tab), { unsaved: true, tabPreviewMode: false }) : tab)));
}
export function markTabSaved(tabid) {
    openedTabs.update(files => files.map(tab => (tab.tabid == tabid ? Object.assign(Object.assign({}, tab), { unsaved: false }) : tab)));
}
export function setSelectedTabFunc(files, tabid, additionalProps = {}) {
    return [
        ...(files || [])
            .filter(x => x.tabid != tabid)
            .map(x => (Object.assign(Object.assign({}, x), { selected: false, focused: false }))),
        ...(files || [])
            .filter(x => x.tabid == tabid)
            .map(x => (Object.assign(Object.assign(Object.assign({}, x), { selected: true, focused: false }), additionalProps))),
    ];
}
export function setSelectedTab(tabid) {
    openedTabs.update(tabs => setSelectedTabFunc(tabs, tabid));
}
export function getObjectTypeFieldLabel(objectTypeField, driver) {
    var _a;
    if (objectTypeField == 'matviews')
        return 'Materialized Views';
    if (objectTypeField == 'collections')
        return (_a = _.startCase(driver === null || driver === void 0 ? void 0 : driver.collectionPluralLabel)) !== null && _a !== void 0 ? _a : 'Collections/Containers';
    return _.startCase(objectTypeField);
}
export function asyncFilter(arr, predicate) {
    return __awaiter(this, void 0, void 0, function* () {
        const results = yield Promise.all(arr.map(predicate));
        return arr.filter((_v, index) => results[index]);
    });
}
export function isMac() {
    var _a;
    // @ts-ignore
    const platform = (navigator === null || navigator === void 0 ? void 0 : navigator.platform) || ((_a = navigator === null || navigator === void 0 ? void 0 : navigator.userAgentData) === null || _a === void 0 ? void 0 : _a.platform) || 'unknown';
    return platform.toUpperCase().indexOf('MAC') >= 0;
}
export function formatKeyText(keyText) {
    if (isMac()) {
        return keyText
            .replace(/CtrlOrCommand\+/g, '⌘ ')
            .replace(/Shift\+/g, '⇧ ')
            .replace(/Alt\+/g, '⌥ ')
            .replace(/Command\+/g, '⌘ ')
            .replace(/Ctrl\+/g, '⌃ ')
            .replace(/Backspace/g, '⌫ ');
    }
    return keyText.replace(/CtrlOrCommand\+/g, 'Ctrl+');
}
export function resolveKeyText(keyText) {
    if (isMac()) {
        return keyText.replace(/CtrlOrCommand\+/g, 'Command+');
    }
    return keyText.replace(/CtrlOrCommand\+/g, 'Ctrl+');
}
export function isCtrlOrCommandKey(event) {
    if (isMac()) {
        return event.metaKey;
    }
    return event.ctrlKey;
}
export function loadSchemaList(conid, database) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            loadingSchemaLists.update(x => (Object.assign(Object.assign({}, x), { [`${conid}::${database}`]: true })));
            const schemas = yield getSchemaList({ conid, database });
            if (schemas.errorMessage) {
                showSnackbarError(`Error loading schemas: ${schemas.errorMessage}`);
                console.error('Error loading schemas', schemas.errorMessage);
                return;
            }
            return schemas;
        }
        finally {
            loadingSchemaLists.update(x => _.omit(x, [`${conid}::${database}`]));
        }
    });
}
export function switchCurrentDatabase(data) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (((_a = data === null || data === void 0 ? void 0 : data.connection) === null || _a === void 0 ? void 0 : _a.useSeparateSchemas) && !isCompositeDbName(data.name)) {
            const conid = data.connection._id;
            const database = data.name;
            const storageKey = `selected-schema-${conid}-${database}`;
            const schemaInStorage = localStorage.getItem(storageKey);
            const schemas = yield loadSchemaList(conid, database);
            if (!schemas)
                return;
            const driver = findEngineDriver(data.connection, getExtensions());
            const defaultSchema = findDefaultSchema(schemas, driver === null || driver === void 0 ? void 0 : driver.dialect, schemaInStorage);
            currentDatabase.set(Object.assign(Object.assign({}, data), { name: `${data.name}::${defaultSchema}` }));
        }
        else {
            currentDatabase.set(data);
        }
    });
}
